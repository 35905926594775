
import { httpPost } from '@/api';
import {
  ajaxLoading,
  errorMessage,
  setTitleLabel,
  successMessage,
} from '@/utils/publicMethods';
import { defineComponent } from 'vue';
import useFormData from './useSortFeeLabelFormData';
import draggable from 'vuedraggable';
export default defineComponent({
  components: { draggable },
  props: {
    /** 新增编辑表单状态 1新增 2编辑 */
    formType: {
      type: [Number],
      default: 1,
    },
    data: {
      type: [Object],
    },
  },
  emits: ['close'],
  setup(props, ctx) {
    const params: any = {};
    const {
      rules,
      formData,
      formRef,
      setFormData,
      RESULT_ARRAY,
      shortArrays,
    } = useFormData(params);
    const close = (status = false) => {
      ctx.emit('close', status);
    };
    const filterFormData = (data, type) => {
      let orderDtos: any[] = [];
      let reapetOrder = 0;
      shortArrays.value.forEach((v, index) => {
        if (v.feeName !== '其他杂费...') {
          const obj = {
            id: v.id,
            order: index + 1,
          };
          orderDtos.push(obj);
        } else {
          reapetOrder = index + 1;
        }
      });
      const filterOrderDtos: any = [];
      const filterNoOrderArr =
        type === 1
          ? RESULT_ARRAY.value.filter((v) => !v.qhIsOrder)
          : RESULT_ARRAY.value.filter((v) => !v.xhIsOrder);
      filterNoOrderArr.forEach((v) => {
        const obj = {
          id: v.id,
          order: reapetOrder,
        };
        filterOrderDtos.push(obj);
      });
      orderDtos = orderDtos.concat(filterOrderDtos);
      return orderDtos;
    };
    const submitData = async () => {
      const orderDtos = filterFormData(shortArrays, formData.orderType);
      formData.orderDtos = orderDtos;
      const params = {
        ...formData,
      };
      ajaxLoading.lock();
      const res = await httpPost('/malicrm/fee/editFeeOrder', params);
      if (res.code === 200) {
        ajaxLoading.unLock();
        successMessage(res.message);
        close(true);
      } else {
        ajaxLoading.unLock();
        errorMessage(res.message);
      }
    };
    /** 提交表单 */
    const saveFormData = async () => {
      formRef.value.validate((valid) => {
        if (valid) {
          submitData();
        } else {
          return false;
        }
      });
    };
    const compare = (property) => {
      return function (a, b) {
        const value1 = a[property];
        const value2 = b[property];
        return value1 - value2;
      };
    };
    const shortArraysFn = (data, name) => {
      // 区分是期货还是现货排序
      let otherArray: any = [];
      let filterOtherArr: any = []; // 杂费类型不排序的值
      const otherObj = {
        feeName: '其他杂费...',
        id: null,
        qhOrder: Math.min(
          ...data.filter((v) => !v.qhIsOrder).map((v) => v.qhOrder)
        ),
        xhOrder: Math.min(
          ...data.filter((v) => !v.xhIsOrder).map((v) => v.xhOrder)
        ),
      };
      if (name === 'qhOrder') {
        filterOtherArr = data.filter((v) => !v.qhIsOrder);
        otherArray = data.filter((v) => v.qhIsOrder);
      } else {
        filterOtherArr = data.filter((v) => !v.xhIsOrder);
        otherArray = data.filter((v) => v.xhIsOrder);
      }
      otherObj.id = (filterOtherArr[0] as any).id;
      otherArray.push(otherObj);
      return otherArray.sort(compare(name));
    };
    /** 费用排序change事件 */
    const changeShort = (value) => {
      console.log('value', value);
      if (value === 1) {
        shortArrays.value = shortArraysFn(RESULT_ARRAY.value, 'qhOrder');
      } else {
        shortArrays.value = shortArraysFn(RESULT_ARRAY.value, 'xhOrder');
      }
    };
    // 初始化数据
    const initData = async () => {
      const params = {};
      const res = await httpPost('/malicrm/fee/queryFeeWithPageList', params);
      if (res.code === 200) {
        RESULT_ARRAY.value = Object.assign([], res.result);
        shortArrays.value = shortArraysFn(res.result, 'qhOrder');
      } else {
        errorMessage(res.message);
      }
    };
    if (props.formType !== 1) {
      const feeObj = JSON.parse(JSON.stringify(props.data));
      setFormData(feeObj.value);
    }
    initData();
    return {
      close,
      formData,
      saveFormData,
      setFormData,
      rules,
      formRef,
      setTitleLabel,
      changeShort,
      initData,
      compare,
      shortArraysFn,
      RESULT_ARRAY,
      shortArrays,
      filterFormData,
    };
  },
});
