import { CrmFilterOptions, CrmTableOptions } from '@/types/type';
import { defineComponent, ref } from 'vue';
import {
  erpConfirm,
  errorMessage,
  successMessage,
} from '@/utils/publicMethods';
import { httpPost } from '@/api';
export default (Instance: any) => {
  const tableRef = ref();
  const filterOptions: CrmFilterOptions[] = [];
  const columnList: CrmTableOptions[] = [
    {
      label: '费用名称',
      prop: 'feeName',
      minWidth: 120,
    },
    {
      label: '期货排序',
      prop: 'qhOrder',
      minWidth: 60,
    },
    {
      label: '现货排序',
      prop: 'xhOrder',
      minWidth: 60,
    },
    {
      label: '备注',
      prop: 'remark',
      width: 200,
    },
    {
      label: '操作',
      prop: '',
      component: defineComponent({
        template: `
          <div class="crm-btn-groups">
          <el-button type="warning" plain @click="edit(row)">编辑</el-button>
          <el-button  type="info"  v-if="!row.isUsed" plain @click="deleteRow(row)">删除</el-button>
          </div>
        `,
        props: ['row'],
        setup() {
          /** 编辑 */
          const edit = (row) => {
            Instance.setupState.rowData.value = row;
            Instance.setupState.setFormType(2);
            Instance.setupState.setShowType(2);
            Instance.setupState.showVisible();
          };
          /** 删除 */
          const deleteRow = (row) => {
            Instance.setupState.rowData.value = row;
            erpConfirm('是否删除费用？')
              .then(async () => {
                const res = await httpPost('/malicrm/fee/deleteFee', {
                  ...row,
                });
                if (res.code === 200) {
                  successMessage('删除成功');
                  Instance.setupState.refreshTable(true);
                } else {
                  errorMessage(res.message);
                }
              })
              .catch(() => {
                return false;
              });
          };
          return { edit, deleteRow };
        },
      }),
      width: 220,
    },
  ];
  return { filterOptions, columnList, tableRef };
};
