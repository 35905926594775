import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_crm_list = _resolveComponent("crm-list")!
  const _component_FreeTypeLabelForm = _resolveComponent("FreeTypeLabelForm")!
  const _component_SortFeeLabelForm = _resolveComponent("SortFeeLabelForm")!
  const _component_ErpDrawer = _resolveComponent("ErpDrawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_crm_list, {
      url: "/malicrm/fee/queryFeeWithPageList",
      filterOption: _ctx.filterOptions,
      columOptions: _ctx.columnList,
      ref: "tableRef",
      id: "FreeTypeLabelList",
      isPagination: false,
      isShowFilter: false,
      onRowClick: _ctx.rowClick
    }, {
      header: _withCtx(() => [
        _createElementVNode("button", {
          class: "erp-button primary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setShowType(1)))
        }, "添加费用"),
        _createElementVNode("button", {
          class: "erp-button primary",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setShowType(3)))
        }, "费用排序"),
        _createElementVNode("button", {
          class: "erp-button primary",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.getLoadPage('/cache/cacheIndex')))
        }, "返回"),
        _createElementVNode("button", {
          class: "erp-button primary",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.refreshTable(false)))
        }, "刷新")
      ]),
      _: 1
    }, 8, ["filterOption", "columOptions", "onRowClick"]),
    _createVNode(_component_ErpDrawer, {
      visible: _ctx.visible,
      erp: "",
      onClose: _ctx.closeVisible
    }, {
      default: _withCtx(() => [
        (_ctx.showType === 1 || _ctx.showType === 2)
          ? (_openBlock(), _createBlock(_component_FreeTypeLabelForm, {
              key: 0,
              formType: _ctx.formType,
              data: _ctx.rowData,
              onClose: _ctx.closeVisible
            }, null, 8, ["formType", "data", "onClose"]))
          : _createCommentVNode("", true),
        (_ctx.showType === 3)
          ? (_openBlock(), _createBlock(_component_SortFeeLabelForm, {
              key: 1,
              formType: 1,
              data: _ctx.rowData,
              onClose: _ctx.closeVisible
            }, null, 8, ["data", "onClose"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible", "onClose"])
  ], 64))
}