
import { defineComponent, getCurrentInstance, ref } from 'vue';
import CrmList from '@/components/CrmList/CrmList.vue';
import FreeTypeLabelForm from './components/FreeTypeLabel/freeTypeLabelForm.vue';
import SortFeeLabelForm from './components/SortFeeLabel/SortFeeLabelForm.vue'; // 费用排序
import useListData from './components/useFreeTypeLabelListData';
import useVisible from '@/hooks/useVisible';
import { ajaxLoading } from '@/utils/publicMethods';
import router from '@/router';
export default defineComponent({
  name: 'FreeTypeLabelList',
  components: { CrmList, FreeTypeLabelForm, SortFeeLabelForm },
  props: {},
  setup() {
    const instance = getCurrentInstance() as any;
    const { filterOptions, columnList, tableRef } = useListData(instance);
    const { visible, showVisible, formType, showType } = useVisible();
    const rowData = ref({});
    const refreshTable = () => {
      tableRef.value.refreshTable(false);
    };
    const rowClick = (row) => {
      console.log(' ', row);
    };
    const setFormType = (type: number): void => {
      formType.value = type;
    };
    const setShowType = (type: number): void => {
      showVisible();
      showType.value = type;
    };
    const closeVisible = (status) => {
      visible.value = false;
      // 解除重复提交
      ajaxLoading.unLock();
      if (status) {
        refreshTable();
      }
    };
    const getLoadPage = (url) => {
      router.push(url);
    };
    return {
      filterOptions,
      columnList,
      tableRef,
      refreshTable,
      visible,
      formType,
      showType,
      showVisible,
      setShowType,
      closeVisible,
      setFormType,
      rowClick,
      getLoadPage,
      rowData,
    };
  },
});
