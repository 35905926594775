import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3748d599"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mali-edit__form" }
const _hoisted_2 = { class: "flex-tags" }
const _hoisted_3 = { class: "tag-item" }
const _hoisted_4 = { class: "crm-button__group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_CrmFormLayout = _resolveComponent("CrmFormLayout")!

  return (_openBlock(), _createBlock(_component_CrmFormLayout, {
    title: _ctx.setTitleLabel(_ctx.formType, ['费用']) + '排序',
    onClose: _ctx.close
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        model: _ctx.formData,
        "label-position": "top",
        ref: "formRef",
        rules: _ctx.rules
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_row, { gutter: 80 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "排序类型",
                      prop: "orderType"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          modelValue: _ctx.formData.orderType,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.orderType) = $event)),
                          onChange: _ctx.changeShort
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_option, {
                              value: 1,
                              label: "期货排序"
                            }),
                            _createVNode(_component_el_option, {
                              value: 2,
                              label: "现货排序"
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue", "onChange"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, { label: "排序列表(拖拽标签修改排序位置)" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                          _createVNode(_component_draggable, {
                            "ghost-class": "ghost",
                            modelValue: _ctx.shortArrays,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.shortArrays) = $event)),
                            tag: "transition-group",
                            "item-key": "id"
                          }, {
                            item: _withCtx(({ element }) => [
                              _createElementVNode("div", _hoisted_3, _toDisplayString(element.feeName), 1)
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["model", "rules"]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          class: "crm-form__button primary",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.saveFormData && _ctx.saveFormData(...args)))
        }, " 保存 "),
        _createElementVNode("button", {
          class: "crm-form__button cancel",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.close(false)))
        }, " 取消 ")
      ])
    ]),
    _: 1
  }, 8, ["title", "onClose"]))
}