
import { httpPost } from '@/api';
import { ajaxLoading, errorMessage, setTitleLabel, successMessage } from '@/utils/publicMethods';
import { defineComponent } from 'vue';
import useFormData from './useFreeTypeLabelFormData';
export default defineComponent({
  components: {},
  props: {
    /** 新增编辑表单状态 1新增 2编辑 */
    formType: {
      type: [Number],
      default: 1,
    },
    data: {
      type: [Object],
    },
  },
  emits: ['close'],
  setup(props, ctx) {
    const params: any = {};

    const { rules, formData, formRef, setFormData } = useFormData(params);
    const close = (status = false) => {
      ctx.emit('close', status);
    };
    const submitData = async () => {
      const params = {
        ...formData,
      };
      ajaxLoading.lock();
      const res = await httpPost('/malicrm/fee/operateFee', params);
      if (res.code === 200) {
        ajaxLoading.unLock();
        successMessage('保存成功');
        close(true);
      } else {
        ajaxLoading.unLock();
        errorMessage(res.message);
      }
    };
    /** 提交表单 */
    const saveFormData = async () => {
      formRef.value.validate((valid) => {
        if (valid) {
          submitData();
        } else {
          return false;
        }
      });
    };
    if (props.formType !== 1) {
      const feeObj = JSON.parse(JSON.stringify(props.data));
      setFormData(feeObj.value);
    }
    return {
      close,
      formData,
      saveFormData,
      setFormData,
      rules,
      formRef,
      setTitleLabel,
    };
  },
});
