import { reactive, ref } from 'vue';
type FormData = {
  version: string | null;
  id: string | null; // id(添加时不需传值)
  qhIsOrder: boolean; // 是否期货排序
  xhIsOrder: boolean; // 是否现货排序
  xhOrder: number; // 现货排序
  remark: string; // 备注
  feeName: string; // 费用名称
  isUsed: boolean | null; // 费用名称
  apFeeCategory: string | number; // 费用名称
  arFeeCategory: string | number; // 费用名称
  [propName: string]: any;
};
export default (params) => {
  const formRef = ref(); // 表单校验对象使用
  const formData: FormData = reactive({
    version: null,
    id: null,
    qhIsOrder: true,
    xhIsOrder: true,
    remark: null,
    feeName: null,
    isUsed: null,
    apFeeCategory: null,
    arFeeCategory: null,
    ...params,
  });
  const rules = reactive({
    feeName: [
      {
        required: true,
        message: '请输入费用名称',
        trigger: 'blur',
      },
    ],
  });
  const setFormData = (paramsData: any) => {
    for (const key in formData) {
      if (Object.prototype.hasOwnProperty.call(paramsData, key)) {
        formData[key] = paramsData[key];
      }
    }
  };
  return { rules, formData, formRef, setFormData };
};
