import { reactive, ref, toRefs } from 'vue';
type OrderItem = {
  id: string | number;
  order: string | number;
};
type FormData = {
  orderType: number | string;
  orderDtos: OrderItem[];
  [propName: string]: any;
};
type CustomForm = {
  RESULT_ARRAY: any[];
  shortArrays: any[];
};
export default (params) => {
  const formRef = ref(); // 表单校验对象使用
  const formData: FormData = reactive({
    orderType: 1,
    orderDtos: null,
    ...params,
  });
  const rules = reactive({});
  const customFormData: CustomForm = reactive({
    RESULT_ARRAY: [],
    shortArrays: [],
  });
  const setFormData = (paramsData: any) => {
    for (const key in formData) {
      if (Object.prototype.hasOwnProperty.call(paramsData, key)) {
        formData[key] = paramsData[key];
      }
    }
  };
  return { rules, formData, formRef, setFormData, ...toRefs(customFormData) };
};
